<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>
<style>
p{
  line-height:24px;
}
.btn-multiline > span {
  width: 100%;
}
.v-skeleton-loader.v-skeleton-loader--is-loading.v-skeleton-loader--tile.theme--light,
.v-skeleton-loader__image.v-skeleton-loader__bone {
  height: 100%;
}
.v-toolbar--prominent .v-toolbar__content {
  align-items: center;
}
.v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: center;
  align-content: stretch;
}
.v-toolbar__extension {
  padding: 0 !important;
}
.subnavbuttons .v-btn__content {
  display: unset;
}
.v-btn.activeLink {
  background-color: #ffffff !important;
}
.v-btn.activeLink span {
  background-color: #ffffff !important;
  color: #e51e25 !important;
}

.v-btn.subnavActiveLink span {
  color: #ffffff !important;
}
.v-btn.subnavActiveLink {
  background-color: #e51e25 !important;
}
.leftBtn .v-btn__content {
  justify-content: flex-start !important;
}
p {
  margin-bottom: 12px !important;
}
.firstletter::first-letter {
  font-size: 200%;
  color: #e51e25;
}
a {
  text-decoration: none;
}
.list-none >li{
  list-style: none;
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: unset;
}
.custom-input-field.v-text-field .v-input__control .v-input__slot {
  padding-right: 0px !important;
  padding-left: 12px !important;
}
.custom-input-field.v-text-field
  .v-input__control
  .v-input__slot
  .v-select__slot {
  height: 100%;
}
.custom-input-field.v-text-field
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-input__append-inner {
  padding: 0 6px;
  margin: 0;
  align-self: center;
}
.custom-input-field.v-text-field
  .v-input__control
  .v-input__slot
  .v-select__slot
  input {
  align-self: unset;
}
.v-expansion-panel .v-expansion-panel-header {
  min-height: 48px !important;
  height: 48px;
}
.custom-input-field.v-text-field .v-input__append-outer {
  margin: 0px !important;
}
.custom-input-field.v-text-field .v-input__append-outer {
  align-self: stretch !important;
  padding: 0px 18px !important;
  background-color: #e51e25 !important;
  align-items: center;
}
.custom-input-field.v-text-field .v-input__append-outer i {
  color: #ffffff;
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
.btn-cap {
  text-transform: capitalize;
}
.disable-events {
  pointer-events: none;
}
.text-trunc {
  padding-bottom: 7px;
  padding-top: 7px;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 40px;
}
.text-trunc-2 {
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.text-trunc-3 {
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.inactive-download-btn {
  right: -160px;
  top: 40vh;
  transition-property: all;
  transition-duration: 500ms;
}
.active-download-btn {
  right: 0;
  top: 40vh;
  transition-property: all;
  transition-duration: 500ms;
}
</style>
<script>
import AdminLayout from "./layout/Admin";
import GuestLayout from "./layout/Guest";
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  components: {
    'adminLayout': AdminLayout,
    'guestLayout': GuestLayout,
  },
};
</script>
