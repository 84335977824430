import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSnip from 'vue-snip'
import { func } from './func.js'
import VueGtag from "vue-gtag";


Vue.use(VueGtag, {
  config: { id: "G-2XY2DLCLR1" }, appName: 'taklairaq.com',
  pageTrackerScreenviewEnabled: true
}, router);
Vue.config.productionTip = false
Vue.use(VueSnip)
Vue.prototype.$func = func
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

