<template>
  <div>
    <v-card
      :ripple="false"
      outlined
      style="display: flex; flex-direction: column"
      min-height="410px"
      class="pb-2"
      height="500px"
      tile
      :hover="!$vuetify.breakpoint.mobile"
      :to="$func.generateProductLink(product)"
      width="225px"
    >
      <v-img
        style="position: relative; overflow: inherit"
        contain
        eager
        width="100%"
        max-height="55%"
        :src="$store.state.imagePath+'products/' + product.img"
      >
        <v-btn
          plain
          retain-focus-on-click
          :ripple="false"
          @click.prevent="remove()"
          absolute
          top
          right
          icon
          small
        >
          <v-icon color="secondary">fa-solid fa-xmark</v-icon></v-btn
        >
        <v-expand-transition>
          <v-img
            v-show="itemIsFave"
            style="margin-top: -5px"
            height="46"
            width="46"
            src="https://img.icons8.com/fluency/48/000000/bookmark-ribbon.png"
          >
          </v-img>
        </v-expand-transition>

        <template v-slot:placeholder>
          <v-skeleton-loader tile loading width="100%" type="image">
          </v-skeleton-loader>
        </template>
        <v-btn
          bottom
          @click.prevent="changeFavStatus()"
          absolute
          elevation="1"
          right
          fab
          style="right: 50px"
          color="trinary primary--text"
          x-small
          depressed
          retain-focus-on-click
        >
         <v-icon v-if="itemIsFave"> fa-solid fa-star</v-icon>
        <v-icon v-else>fa-regular fa-star </v-icon>
        </v-btn>
        <v-speed-dial
        v-model="openShareOptions"
        :open-on-hover="!$vuetify.breakpoint.mobile"
        bottom
        right
        absolute
        style="bottom: -16px"
        direction="top"
        transition="slide-y-reverse-transition"
        >
          <template v-slot:activator>
          <v-btn
            v-on:click.stop.prevent="openShareOptions = !openShareOptions"
            elevation="1"
            fab
            color="trinary secondary--text"
            x-small
            depressed
            retain-focus-on-click
            :ripple="false"
          >
            <v-icon> fa-share </v-icon>
          </v-btn>
          </template>
          <v-btn
            target="_blank"
            v-for="(link, i) in $store.state.shareOnSocialMediaLinks"
            :key="i"
            elevation="1"
            @click.prevent="share(link.link + $func.generateProductLink(product))"
            fab
            x-small
          >
            <v-icon :color="link.color">{{ link.icon }}</v-icon>
          </v-btn>
        </v-speed-dial>
      </v-img>
      <v-divider></v-divider
      ><v-card-title
        
        class="text-subtitle-1 secondary--text text-trunc"
        >{{ product.title }}
      </v-card-title>
      <v-card-subtitle class="text-overline primary--text"
        ><div>
          {{ product.brand.title }}
        </div>
        <div class="text-caption font-italic secondary--text">
          {{ product.date }}
        </div>
      </v-card-subtitle>

      <v-card-text class="pb-0 text-trunc-2">{{
        product.properties || "No available details"
      }}</v-card-text>
      <v-card-actions style="position: absolute; width: 100%; bottom: 0px">
        <v-btn
          tile
          depressed
          class="disable-events btn-cap"
          block
          outlined
          :color="product.availability ? 'green' : 'primary'"
        >
          {{ product.availability ? "Available" : "Out of stock" }}
          <v-icon
            class="py-1"
            :color="product.availability ? 'green' : 'primary'"
            small
          >
            {{
              product.availability ? "fa-solid fa-check" : "fa-solid fa-xmark"
            }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    openShareOptions: false,
  }),
  props: {
    product: { type: Object, required: true },
    type: { type: String, required: true },
  },
  computed: {
    
    itemIsFave() {
      if (
        this.$store.state.favoriteList.filter((e) => e.id === this.product.id)
          .length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    share(link) {
      window.open(link, "_blank");
    },
    remove() {
      switch (this.type) {
        case "fav":
          this.$store.commit("removeItemFromfavoriteList", this.product);
          break;

        case "recently":
          this.$store.commit("removeItemFromrecentlyViewed", this.product);
          break;
        default:
          break;
      }
      this.$store.commit("removeItemFromrecentlyViewed", this.product);
    },

    changeFavStatus() {
      if (this.itemIsFave) {
        this.$store.commit("removeItemFromfavoriteList", this.product);
      } else {
        this.$store.commit("addItemTofavoriteList", this.product);
      }
    },
  },
};
</script>


<style>
</style>