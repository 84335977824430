<template>
  <v-app >
    <v-overlay absolute v-model="menuShow"></v-overlay>
    <v-app-bar
      elevate-on-scroll
      hide-on-scroll
      height="100"
      app
      color="trinary"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:extension>
        <v-row
          @mouseleave="menuShow = false"
          align="center"
          class="fill-height secondary--text trinary pa-0 ma-0"
        >
          <v-btn
            depressed
            v-for="(nav, i) in navs"
            class="text-subtitle-1 btn-cap"
            :key="i"
            @mouseenter="
              menuShow = true;
              selectedNav = nav;
            "
            color="secondary"
            active-class="activeLink"
            height="100%"
            :to="nav.to"
            tile
            text
            plain
            >{{ nav.title }} <v-icon class="ml-1" x-small>fa-chevron-down</v-icon></v-btn
          >

          <v-col cols="12" class="pa-0" style="max-height: 0px">
            <v-expand-transition>
              <v-card
                style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important"
                flat
                tile
                class="trinary"
                v-show="menuShow"
              >
                <v-container fluid>
                  <v-layout row wrap fill-height align-center>
                    <v-expand-transition
                      mode="in-out"
                      v-for="(subNav, i) in selectedNav.childrens"
                      :key="i"
                    >
                      <v-flex sm6 md3 class="lg5-custom py-0 px-0">
                        <v-btn
                          depressed
                          style="text-transform: capitalize"
                          class="
                            text-body-2
                            leftBtn
                            btn-multiline
                            font-weight-light
                          "
                          active-class="activeLink"
                          color="secondary"
                          block
                          text
                          plain
                          height="48px"
                          tile
                          :to="subNav.to"
                          small
                        >
                          <span class="text-wrap">
                            {{ subNav.title }}
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-expand-transition>
                  </v-layout>
                </v-container>
              </v-card>
            </v-expand-transition>
          </v-col>
        </v-row>
      </template>
      <div style="width: 100%">
        <v-row
          style="position: relative"
          class="pa-0 ma-0 text-center"
          align="center"
        >
          <v-col class="pa-0 ma-0" cols="12" md="2">
            <div>
              <v-img
                style="cursor: pointer"
                @click="$router.push('/').catch(() => {})"
                alt="takla-logo"
                class="shrink mx-auto"
                height="40"
                src="../assets/logo.png"
                width="150"
              >
              </v-img></div
          ></v-col>
          <v-col class="pa-0 ma-0" cols="12" md="8">
            <SearchField />
          </v-col>
          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            class="pa-0"
            cols="12"
            md="2"
          >
            <v-btn
              elevation="0"
              color="#F7F9FB"
              x-small
              fab
              :href="link.link"
              v-for="(link, i) in $store.state.socialMediaLinks"
              :key="i"
              class="mx-1 white--text"
            >
              <v-icon :color="link.color" size="20px">
                {{ link.icon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      style="min-height: 70%"
      overlay-color="secondary"
      app
      v-model="drawer"
      bottom
      temporary
      ><v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Takla Trading Iraq
          </v-list-item-title>
          <v-list-item-subtitle
            >Your Tools and Hardware Store — Takla Trading</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-group no-action v-for="nav in navs" :key="nav.id">
          <template v-slot:activator>
            <v-list-item-title class="btn-cap text-subtitle-1">{{
              nav.title
            }}</v-list-item-title>
          </template>
          <v-list-item
            :to="subNav.to"
            v-for="(subNav, i) in nav.childrens"
            :key="i"
            link
          >
            <v-list-item-title class="text-subtitle-2">{{
              subNav.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-sheet
        class="fill-height"
        @mouseenter="setActive(null)"
        color="#F7F9FB"
      >
        <v-btn
          fab
          small
          bottom
          right
          fixed
          class="trinary primary--text"
          v-if="$vuetify.breakpoint.mobile"
          @click.stop="drawer = !drawer"
        >
          <v-icon small color="primary">fa-solid fa-right-from-bracket</v-icon>
        </v-btn>
        <v-speed-dial
          v-if="$vuetify.breakpoint.mobile"
          fixed
          bottom
          right
          style="bottom: 60px"
          direction="top"
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator>
            <v-btn fab class="trinary primary--text" small>
              <v-icon>fa-solid fa-hashtag </v-icon>
            </v-btn>
          </template>
          <v-btn
            :href="link.link"
            v-for="(link, i) in $store.state.socialMediaLinks"
            :key="i"
            elevation="1"
            fab
            small
          >
            <v-icon :color="link.color"> {{ link.icon }}</v-icon>
          </v-btn>
        </v-speed-dial>
        <KeepAlive>
          <router-view
            :key="!$route.meta.reuse ? $route.fullPath : null"
          ></router-view>
        </KeepAlive>
        <v-expansion-panels tile multiple hover flat accordion v-model="panel">
          <v-expansion-panel v-show="$store.state.recentlyViewed.length > 0">
            <v-expansion-panel-header class="text-subtitle-2 white--text primary"
              >Item recently viewed (
              {{ $store.state.recentlyViewed.length }}
              )</v-expansion-panel-header
            >
            <v-expansion-panel-content color="#F7F9FB">
              <v-slide-group
                show-arrows="desktop"
                v-model="recentlyViewedSlider"
              >
                <v-slide-item
                  class="ma-3"
                  v-for="(product, i) in $store.state.recentlyViewed"
                  :key="i"
                >
                  <product-card-customized
                    type="recently"
                    :product="product"
                  ></product-card-customized>
                </v-slide-item>
              </v-slide-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-show="$store.state.favoriteList.length > 0">
            <v-expansion-panel-header class="text-subtitle-2  primary--text"
              >Your favorites (
              {{ $store.state.favoriteList.length }}
              )</v-expansion-panel-header
            >
            <v-expansion-panel-content color="#F7F9FB">
              <v-slide-group
                show-arrows="desktop"
                center-active
                v-model="favoriteListSlider"
              >
                <v-slide-item
                  class="ma-3"
                  v-for="(product, i) in $store.state.favoriteList"
                  :key="i"
                >
                  <product-card-customized
                    type="fav"
                    :product="product"
                  ></product-card-customized>
                </v-slide-item>
              </v-slide-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog
          v-model="$store.state.loading"
          hide-overlay
          persistent
          width="300"
        >
          <v-card color="trinary">
            <v-card-text class="secondary--text">
              Please stand by
              <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-sheet>
    </v-main>
    <v-footer color="trinary" class="scondary--text" padless tile>
      <v-container fluid class="pt-0 px-0">
        <v-row class="pa-0 ma-0" justify="start">
          <v-col
            style="
              height: 350px;
              flex-direction: column;
              justify-content: space-between;
            "
            class="px-3 py-2 py-sm-0 d-flex"
            cols="12"
            md="4"
            sm="6"
            ><div>
              <div class="text-subtitle-1 font-weight-black">
                Newsletter & Social Media
              </div>
              <div class="text-subtitle-2 font-weight-medium py-3">
                keep yourself up to date with our latest deals and products by
                subscribing to our newsletter
              </div>
              <div class="py-2">
                <v-btn
                  elevation="0"
                  color="#F7F9FB"
                  x-small
                  fab
                  :href="link.link"
                  v-for="(link, i) in $store.state.socialMediaLinks"
                  :key="i"
                  class="mx-1 white--text"
                >
                  <v-icon :color="link.color" size="20px">
                    {{ link.icon }}
                  </v-icon>
                </v-btn>
              </div>
              <v-row style="max-height: 38px" class="ma-0 py-0">
                <v-col class="px-0 py-0" cols="9">
                  <v-text-field
                    prepend-inner-icon="fa-solid fa-envelope"
                    solo-inverted
                    class="rounded-0"
                    hide-details=""
                    type="email"
                    flat
                    dense
                    placeholder="Email"
                    solo
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-0 px-0" cols="3">
                  <v-btn
                    class="btn-cap"
                    small
                    max-width="100%"
                    block
                    height="38"
                    color="primary"
                    depressed
                    tile
                  >
                    subscribe</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <div>
              <div class="text-subtitle-1 font-weight-black">E-catalogue</div>
              <div class="text-subtitle-2 font-weight-medium py-3">
                Feel free to download our E-catalogue to browse all our
                equipments and proudcts.
              </div>
              <div class="pt-3">
                <v-btn
                  download
                  class="btn-cap"
                  href="https://taklairaq.com/Takla-E-Catalog.pdf"
                  small
                  max-width="100%"
                  block
                  height="38"
                  color="primary"
                  depressed
                  tile
                >
                  <v-icon class="mr-4">fa-solid fa-download</v-icon>
                  download our E-catalogue
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col class="pa-0" cols="12" md="8" sm="6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3221.497339155641!2d43.9895633659071!3d36.154450483053026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40073cb139fef519%3A0x90f8b76bcbe84bae!2sTakla%20for%20General%20Trading!5e0!3m2!1sen!2slb!4v1675195956976!5m2!1sen!2slb"
              width="100%"
              height="100%"
              :style="
                'border: 0;' +
                ($vuetify.breakpoint.name == 'xs' ? 'min-height:350px' : '')
              "
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe
          ></v-col>
        </v-row>

        <v-container fluid>
          <v-row class="pa-0 ma-0" justify="start">
            <v-col class="pl-0" cols="12" lg="12">
              <div class="text-subtitle-1 font-weight-black">
                Our Branches in Iraq
              </div>
              <v-row class="pa-0 ma-0" justify="start">
                <v-col
                  v-for="branch in branches"
                  :key="branch.address1"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <h5 class="font-weight-medium text-subtitle-2 btn-cap">
                    {{ branch.address1 }}
                  </h5>
                  <h6 class="font-weight-medium text-subtitle-2 btn-cap">
                    {{ branch.address2 }}
                  </h6>

                  <ul class="pa-0">
                    <li
                      style="list-style-type: none; white-space: nowrap"
                      class="pa-0 ma-0 subtitle-2 font-weight-regular"
                      v-for="(contact, i) in branch.contacts"
                      :key="i"
                    >
                      <v-icon small color="primary">fa-solid fa-envelope</v-icon>
                      <a href="" class="secondary--text"> {{ contact }}</a>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid>
          <div class="text-subtitle-1 font-weight-black">Quick Links</div>
          <v-row class="pa-0 ma-0" justify="start">
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="(nav, i) in even(navs)"
              :key="i"
            >
              <div
                class="
                  font-weight-medium
                  secondary--text
                  text-subtitle2
                  btn-cap
                "
              >
                <a class="secondary--text" :href="nav.to"> {{ nav.title }}</a>
              </div>
              <ul>
                <li
                  style="list-style-type: none; white-space: nowrap btn-cap"
                  class="pa-0 ma-0 text-subtitle-2 font-weight-regular"
                  v-for="(subNav, i) in nav.childrens"
                  :key="i"
                >
                  -
                  <a class="secondary--text btn-cap" :href="subNav.to">
                    {{ subNav.title }}</a
                  >
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-footer>
    <v-footer
      inset
      tile
      padless
      color="white secondary--text"
      class="text-center"
    >
      <v-container fluid>
        <v-divider></v-divider>
        <p style="width: 100%" class="text-overline mb-0">
          website developed by
          <a href="https://kurdistanhub.com">KurdistanHub</a> | Copyright
        </p>
        <div style="width: 100%" class="text-caption mb-0">
          Copyright © 2023 Takla Trading. All Rights Reserved.
        </div>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "@/plugins/http.service.js";
import ProductCardCustomized from "../components/ProductCardCustomized.vue";
import SearchField from "../components/SearchField.vue";
export default {
  name: "App",
  components: {
    ProductCardCustomized,
    SearchField,
  },
  computed: {
    getStoreCategories() {
      return this.$store.state.categories;
    },
  },

  methods: {
    ok() {
      console.log("o");
    },
    generateNav() {
      this.getStoreCategories.forEach((category) => {
        const length = category.subCategories.length;
        category.length = length;
        category.subCategories.forEach((subCategory) => {
          subCategory.to = this.generateSubnNavLinkUrl(subCategory, category);
        });
        const navObject = {};
        navObject.title = category.title;
        navObject.id = category.id;
        navObject.to = this.generateLinkUrl(category);
        navObject.description = category.description;
        navObject.img = category.img;
        navObject.childrens = category.subCategories;
        navObject.length = category.length;
        this.navs.push(navObject);
      });
      this.$store.commit("setNavs", this.navs);
    },
    generateLinkUrl(nav) {
      return (
        "/categories/" +
        nav.title.replace(/\s+/g, "-").toLowerCase() +
        "-" +
        nav.id
      );
    },
    generateSubnNavLinkUrl(subNav, nav) {
      return (
        "/categories/" +
        nav.title.replace(/\s+/g, "-").toLowerCase() +
        "-" +
        nav.id +
        "/" +
        subNav.title.replace(/\s+/g, "-").toLowerCase() +
        "-" +
        subNav.id
      );
    },

    even(arr) {
      // Set slice() to avoid to generate an infinite loop!
      return arr.slice().sort(function (a, b) {
        return b.length - a.length;
      });
    },
    setActive(i) {
      this.activeLink = i;
    },
  },
  mounted() {},
  destroyed() {},
  created() {
    this.$store.commit("initialiseStore");
    axios
      .post("api/web/category/get-categories")
      .then((res) => {
        if (res.data.responseCode === 1) {
          this.$store.commit("setCategories", res.data.data);
          this.generateNav();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("api/web/country/get-countries")
      .then((res) => {
        if (res.data.responseCode === 1) {
          this.$store.commit("setCountries", res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("api/web/brand/get-brands")
      .then((res) => {
        if (res.data.responseCode === 1) {
          this.$store.commit("setBrands", res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  data: () => ({
    navs: [
      {
        title: "Home",
        to: "/",
        length: 3,
        childrens: [
          { title: "about us", to: "/about-us" },
          { title: "contact us", to: "/contact-us" },
          { title: "news and events", to: "/news-and-events" },
        ],
      },
    ],
    menuShow: false,
    selectedNav: { childrens: [] },
    panel: [0, 1],
    branches: [
      {
        address1: "erbil - kurdistan region",
        address2: "Makhmour Main Road - South Industrial Area",
        contacts: ["tarek.takla@taklairaq.com", "ziad.takla@taklatrading.com"],
      },
      {
        address1: "sulaymaniah - kurdistan region",
        address2: "Raparin Road next to Brayati Gas Station",
        contacts: ["ayman.atef@taklairaq.com", "tarek.takla@taklairaq.com"],
      },
      {
        address1: "duhok - kurdistan region",
        address2: "Barzan Road - Malta Khweri",
        contacts: ["ziyaa.salim@taklairaq.com", "tarek.takla@taklairaq.com"],
      },
    ],
    select: null,
    drawer: null,
    favoriteListSlider: 0,
    recentlyViewedSlider: 0,
    activeLink: null,
    offset: true,
  }),
};
</script>
