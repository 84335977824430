<template>
  <v-app>
    <v-app-bar
      style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important"
      flat
      clipped-left
      app
      height="66.8"
      color="trinary"
    >
      <v-row class="pa-0 ma-0" align="center" justify="space-between">
        <div v-if="$vuetify.breakpoint.mobile">
          <v-btn
            icon
            small
            class="trinary primary--text"
            v-if="$vuetify.breakpoint.mobile"
            @click.stop="drawer = !drawer"
            ><v-icon>fa-solid fa-bars</v-icon>
          </v-btn>
        </div>
        <div>
          <v-img
            alt="takla logo"
            class="shrink mx-auto"
            height="60"
            src="../assets/logo.png"
            width="150"
          >
          </v-img>
        </div>
        <div v-if="!$vuetify.breakpoint.mobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                :ripple="false"
                retain-focus-on-click
                style="text-transform: none"
                v-bind="attrs"
                v-on="on"
              >
                Logged in as:
                <span class="text-decoration-underline">
                  {{ $store.state.admin.email }}</span
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="logout()">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn
            icon
            small
            class="trinary primary--text"
            v-if="$vuetify.breakpoint.mobile"
            @click.stop="$store.commit('logout'), $router.replace('/admin')"
            ><v-icon>fa-solid fa-right-from-bracket </v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      :expand-on-hover="!$vuetify.breakpoint.mobile"
      :mini-variant="!$vuetify.breakpoint.mobile"
      mini-variant-width="80"
      :permanent="!$vuetify.breakpoint.mobile"
      v-model="drawer"
      app
      clipped
      width="300"
      ><v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Takla Trading Iraq
          </v-list-item-title>
          <v-list-item-subtitle
            >Administration — Takla Trading</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense color="">
        <v-list-item
          color="primary"
          :to="nav.to"
          v-for="(nav, i) in adminNavs"
          :key="i"
          link
        >
          <v-list-item-icon>
            <v-icon small color="primary">{{ nav.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-2">{{
            nav.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-sheet class="fill-height" color="#F7F9FB">
        <KeepAlive>
          <router-view
            :key="!$route.meta.reuse ? $route.fullPath : null"
          ></router-view>
        </KeepAlive>

        <v-dialog
          v-model="$store.state.loading"
          hide-overlay
          persistent
          width="300"
        >
          <v-card color="trinary">
            <v-card-text class="secondary--text">
              Please stand by
              <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-sheet>
    </v-main>
    <v-footer
      inset
      tile
      padless
      color="white secondary--text"
      class="text-center"
    >
      <v-container fluid>
        <v-divider></v-divider>
        <p style="width: 100%" class="text-overline mb-0">
          website developed by
          <a href="https://kurdistanhub.com">KurdistanHub</a> | Copyright
        </p>
        <div style="width: 100%" class="text-caption mb-0">
          Copyright © 2023 Takla Trading. All Rights Reserved.
        </div>
      </v-container>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  name: "App",
  components: {},
  computed: {},
  watch: {},
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.replace("/admin");
    },
  },
  mounted() {},
  destroyed() {},
  created() {},
  data: () => ({
    adminNavs: [
      { title: "Dashboard", to: "/admin/dashboard",icon:'fa-solid fa-chart-line' },
      { title: "Categories", to: "/admin/categories",icon:'fa-solid fa-layer-group' },
      { title: "SubCategories", to: "/admin/sub-categories" ,icon:'fa-solid fa-code-branch'},
      { title: "Products", to: "/admin/products" ,icon:'fa-solid fa-barcode'},
      { title: "Brands", to: "/admin/brands" ,icon:'fa-solid fa-braille'},
      { title: "Admins", to: "/admin/admins",icon:'fa-solid fa-toolbox' },
      { title: "Countries", to: "/admin/countries" ,icon:'fa-solid fa-map'},
      { title: "Projects", to: "/admin/projects" ,icon:'fa-solid fa-diagram-project'},
      { title: "News", to: "/admin/news",icon:'fa-solid fa-newspaper' },
    ],
    drawer: null,
  }),
};
</script>
