import axios from "axios";
let API_ENDPOINT = "https://taklairaq.com/";   // demo
// let API_ENDPOINT = "http://localhost:8080/"; /// local
// let API_ENDPOINT = "http://192.168.0.104/takla/";   /// localmobile
let config = {
  baseURL: `${API_ENDPOINT}`,
};
const httpClient = axios.create(config);

export default httpClient;
