export const func = {
    generateProductLink: (product) => {
        return (
            "/categories/" +
            product.subCategory.category.title
                .replace(/\s+/g, "-")
                .toLowerCase() +
            "-" +
            product.subCategory.category.id +
            "/" +
            product.subCategory.title.replace(/\s+/g, "-").toLowerCase() +
            "-" +
            product.subCategory.id +
            "/" +
            product.id
        );
    },
    generateCategoryLink(category) {
        return (
            "/categories/" +
            category.title.replace(/\s+/g, "-").toLowerCase() +
            "-" +
            category.id
        );
    }, 
    generateSubCategoryLink(subCategory, category) {
        return (
            "/categories/" +
            category.title.replace(/\s+/g, "-").toLowerCase() +
            "-" +
            category.id +
            "/" +
            subCategory.title.replace(/\s+/g, "-").toLowerCase() +
            "-" +
            subCategory.id
        );
    },
    generateNewLink(event) {
        return (
            "/news-and-events/" +
            event.title.replace(/\s+/g, "-").toLowerCase() +
            "-" +
            event.id
          );
    },
}