import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/home-index.vue'),
    meta: {
      layout: 'guestLayout'
    },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/about-us-index.vue'),
    meta: {
      layout: 'guestLayout'
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contactUs/contact-us-index.vue'),
    meta: {
      layout: 'guestLayout'
    },
  },
  {
    path: '/categories/:cid',
    name: 'single-category',
    component: () => import(/* webpackChunkName: "singleCategory" */ '../views/singleCategory/single-category.vue'),
    meta: {
      reuse: false,
      layout: 'guestLayout'
    },
  },
  {
    path: '/categories/:cid/:scid',
    name: 'single-subcategory',
    component: () => import(/* webpackChunkName: "singleSubCategory" */ '../views/singleSubCategory/single-subcategory.vue'),
    meta: {
      reuse: false,
      layout: 'guestLayout'
    },
  }, {
    path: '/categories/:cid/:scid/:iid',
    name: 'single-item',
    component: () => import(/* webpackChunkName: "singleItem" */ '../views/singleItem/single-item.vue'),
    meta: {
      reuse: false,
      layout: 'guestLayout'
    },
  },
  {
    path: '/news-and-events',
    name: 'news-and-events',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/newsAndEvents/news-and-events.vue'),
    meta: {
      reuse: false,
      layout: 'guestLayout'
    },
  },
  {
    path: '/news-and-events/:neid',
    name: 'single-new',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/singleNew/single-new-index.vue'),
    meta: {
      reuse: false,
      layout: 'guestLayout'
    },
  },
  {
    path: '/admin/brands',
    name: 'admin-brands',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/brands/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
  {
    path: '/admin/countries',
    name: 'admin-countries',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/countries/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
  {
    path: '/admin/categories',
    name: 'admin-categories',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/categories/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/dashboard/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
  {
    path: '/admin',
    name: 'admin-login',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/login/admin-index.vue'),
    meta: { reuse: false, layout: 'guestLayout' },
  },
  {
    path: '/admin/news',
    name: 'admin-news',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/news/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },

  },
  {
    path: '/admin/newsletter',
    name: 'admin-newsletter',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/newsletter/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },

  },
  {
    path: '/admin/products',
    name: 'admin-products',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/products/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
  {
    path: '/admin/admins',
    name: 'admin-admins',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/admins/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
  {
    path: '/admin/projects',
    name: 'admin-projects',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/projects/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },

  }, {
    path: '/admin/sub-categories',
    name: 'admin-sub-categories',
    component: () => import(/* webpackChunkName: "singleNew" */ '../views/admin/subCategories/admin-index.vue'),
    meta: {
      reuse: false,
      layout: 'adminLayout'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes, scrollBehavior() {
    // always scroll to top
    return { top: 0 }

  },
})
router.beforeEach((to, from, next) => {
  if (to.meta.layout == 'guestLayout') {
    next()
  }
  else {
    if (store.state.admin == null) {
      next("/admin")
    } else {
      next()
    }
  }
});
export default router
