import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: "guestLayout",
    admin: localStorage.getItem('takla_admin') ? JSON.parse(localStorage.getItem('takla_admin')) : null,
    socialMediaLinks: [
      { icon: "fa-brands fa-facebook-f", link: "https://www.facebook.com/takla.iq/", color: "primary" },
      { icon: "fa-brands fa-whatsapp", link: "https://api.whatsapp.com/send?phone=9647507178118", color: "primary" },
      { icon: "fa-brands fa-instagram", link: "https://www.instagram.com/takla.iq/", color: "primary" }
    ],
    shareOnSocialMediaLinks: [
      { icon: "fa-brands fa-telegram", link: "https://t.me/share/url?text=Check This Produc On Takla Iraq!&url=https://www.taklairaq.com" ,color:"#229ED9"},
      { icon: "fa-brands fa-facebook-f", link: "https://www.facebook.com/sharer/sharer.php?u=https://www.taklairaq.com", color: "#1DA1F2" },
      { icon: "fa-brands fa-facebook-messenger", link: "http://www.facebook.com/dialog/send?app_id=123456789&amp;link=http://www.taklairaq.com", color: "#4267B2" },
      { icon: "fa-brands fa-line", link: "https://social-plugins.line.me/lineit/share?url=https://www.taklairaq.com", color: "#0C63BC" },
      { icon: "fa-brands fa-whatsapp", link: "https://api.whatsapp.com/send?text=https://www.taklairaq.com", color: "#075E54" },
    ],
    loading: false,
    confirmDialog: false,
    navs: [],
    categories: [],
    brands: [],
    countries: [],
    favoriteList: null,
    recentlyViewed: null,
    imagePath: "https://taklairaq.com/api/web/uploads/",
    // imagePath: 'http://localhost/takla/api/web/uploads/'
  },

  mutations: {
    openConfirmDialog(state) {
      state.confirmDialog = true
    },
    closeConfirmDialog(state) {
      state.confirmDialog = false
    },
    SET_LAYOUT(state, value) {
      state.layout = value;
    },
    login(state, admin) {
      localStorage.setItem("takla_admin", JSON.stringify(admin)); // OR
      state.admin = admin;
    },
    logout(state) {
      state.admin = null;
      localStorage.removeItem("takla_admin");
    },
    initialiseStore(state) {
      if (localStorage.getItem('recentlyViewed')) {
        state.recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed'))
      }
      else {
        state.recentlyViewed = []
      }
      if (localStorage.getItem('favoriteList')) {
        state.favoriteList = JSON.parse(localStorage.getItem('favoriteList'))
      }
      else {
        state.favoriteList = []
      }

    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setBrands(state, brands) {
      state.brands = brands;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setNavs(state, navs) {
      state.navs = navs;
    },
    addItemTofavoriteList(state, item) {
      state.favoriteList.push(item);
      localStorage.setItem("favoriteList", JSON.stringify(state.favoriteList)); // O
    },
    removeItemFromfavoriteList(state, item) {
      const index = state.favoriteList.map(function (e) { return e.id; }).indexOf(item.id);
      if (index > -1) { // only splice array when item is found
        state.favoriteList.splice(index, 1); // 2nd parameter means remove one item only
      }
      localStorage.setItem("favoriteList", JSON.stringify(state.favoriteList)); // O
    },
    addItemTorecentlyViewed(state, item) {
      const index = state.recentlyViewed.map(function (e) { return e.id; }).indexOf(item.id);
      if (index > -1) { // only splice array when item is found
        return
      }
      else {
        state.recentlyViewed.push(item);
        localStorage.setItem("recentlyViewed", JSON.stringify(state.recentlyViewed));
      }
    },
    removeItemFromrecentlyViewed(state, item) {
      const index = state.recentlyViewed.map(function (e) { return e.id; }).indexOf(item.id);
      if (index > -1) { // only splice array when item is found
        state.recentlyViewed.splice(index, 1); // 2nd parameter means remove one item only
      }
      localStorage.setItem("recentlyViewed", JSON.stringify(state.recentlyViewed)); // O
    },
  },
  actions: {
    setLayout({ commit }, data) {
      commit('SET_LAYOUT', data);
    }

  },
  modules: {
  }
})
