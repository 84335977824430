<template>
  <v-autocomplete :menu-props="{closeOnContentClick:true,transition: true}"
    

    dense
    single-line
    no-filter
    outlined
    append-icon=""
    prepend-inner-icon="fa-brands fa-searchengin"
    color="primary"
    hide-spin-buttons
    :items="searchedProducts"
    :search-input.sync="search"
    
    class="rounded-0"
    flat
 
    hide-details

    persistent-placeholder
    placeholder="What are you looking for?"
    ><template v-slot:no-data>
      <v-list-item dense>
        <v-list-item-title>
          Looks like your search does not match any
          <strong>product</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:item="{ item }"
      ><v-list-item dense :to="$func.generateProductLink(item)">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.subCategory.title }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-img
            aspect-ratio="1"
            height="40"
            width="40"
            contain
            :src="$store.state.imagePath + 'products/' + item.img"
          ></v-img>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "@/plugins/http.service.js";
export default {
  computed: {
    searchedProducts() {
      return this.entries ;
      
    },
  },
  watch: {
    search() {
      this.fetchEntriesDebounced();
    },
  },
  methods: {
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch();
      }, 500);
    },
    fetch() {
    
      axios
        .post("api/web/item/search", { val: this.search.toLowerCase() })
        .then((res) => {
          if (res.data.responseCode === 1) {
            this.entries = res.data.data;

          }
        })
        .catch((error) => {
          this.error = error;
        
          this.errorDialog = true;
        });
    },
  },
  data: () => ({
    loading: false,
    search: null,
    entries: [],
  }),
};
</script>

<style>
</style>